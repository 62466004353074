<template>
  <div class="dialog-form">
    <el-dialog v-model="isShow" :title="cardInfo.cardName ?? '卡详情'" width="30%">
      <el-scrollbar height="400px">
        <div class="use-time">有效期:

          <template v-if="cardDetailInfo.timeLimitFlag == 1">
            永久
          </template>
          <template v-if="cardDetailInfo.timeLimitFlag == 2">
            有效期：{{
                dayjs(cardDetailInfo.fixStartTime).format(
                  'YYYY年MM月DD日'
                )
            }}-{{
    dayjs(cardDetailInfo.fixEndTime).format(
      'YYYY年MM月DD日'
    )
}}
          </template>
          <template v-if="cardDetailInfo.timeLimitFlag == 3">
            购买后{{ cardDetailInfo.timeLimitDays }}天内有效
          </template>
          <template v-if="cardDetailInfo.timeLimitFlag == 4">
            使用后{{ cardDetailInfo.timeLimitDays }}天内有效
          </template>
        </div>
        <div class="handsel">
          <div class="title">卡项权益</div>
          <template :key="item" v-for="item in cardHandselList.giftGoodList">
            <div class="item">
              <div>{{ item.goodName }}</div>
              <div>{{ item.qty }}次</div>
            </div>
          </template>
          <template :key="item" v-for="item in cardHandselList.giftServeList">
            <div class="item">
              <div>{{ item.serveName }}</div>
              <div>{{ item.qty }}次</div>
            </div>
          </template>
          <template :key="item" v-for="item in cardHandselList.bindServeList">
            <div class="item">
              <div>{{ item.serveName }}</div>
              <div>{{ item.qty }}次</div>
            </div>
          </template>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    cardInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    watch(() => props.cardInfo, (n) => {
      initPage()
    }, {
      deep: true
    })

    const cardHandselList = computed(() => store.state.order.cardHandselList)
    const cardDetailInfo = computed(() => store.state.order.cardInfo)
    const initPage = () => {
      if (props.cardInfo.hasOwnProperty('cardId')) {
        store.dispatch('order/getCardInfoAction', {
          companyId: COMPANY_ID,
          cardId: props.cardInfo.cardId
        })
        store.dispatch('order/getCardDetailAction', [props.cardInfo.cardId, COMPANY_ID])
      }
    }
    initPage()


    return {
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      cardHandselList,
      cardDetailInfo,
      dayjs


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  .use-time {
    line-height: 45px;
    background-color: rgb(224, 241, 240);
    padding: 0 10px;
  }

  .handsel {
    .title {
      margin: 20px 0 10px 0;
      color: rgb(181, 181, 181);
    }

    .item {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
