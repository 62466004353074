<template>
  <div class="custom-card">
    <consume-detail-card :currentOptTeacherName="currentOptTeacherName" @handleOptTeacher="handleOptTeacher" @changeCardQty="changeCardQty" @changeRemoveCardOpt="changeRemoveCardOpt" :consumeList="consumeList" @emptyOptList="emptyOptList">
    </consume-detail-card>
    <order-collection @changeOptPayCheckList="changeOptPayCheckList" :optCardNum="consumeList.length" @handlePayClick="handlePayClick" :payInfo="payInfo">
    </order-collection>
    <shopTeacherDialog @changeTeacherId="changeTeacherId" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"></shopTeacherDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, watch } from 'vue'
import CardType from '../../../base-ui/card-type/card-type.vue'
import ConsumeDetailCard from '../../../base-ui/consume-detail-card/consume-detail-card.vue'
import OrderCollection from '../../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import shopTeacherDialog from '../../../base-ui/shop-teacher-dialog/shop-teacher-dialog.vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {

  },
  components: {
    CardType,
    ConsumeDetailCard,
    OrderCollection,
    shopTeacherDialog
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const payInfo = reactive({
      sumPrice: 0,
      date: dayjs(new Date()).format('YYYYMMDDHHmmss')
    })
    const consumeList = ref([])
    const currentOptTeacherId = ref('')

    const handlePayClick = () => {
      let itemname = []
      consumeList.value.forEach(item => {
        itemname.push(item.cardName)
      })

      let adminInfo = cache.getCache('adminInfo')
      let obj = {
        companyId: COMPANY_ID,
        orderKind: consumeList.value[0].kindId,
        totalPrice: payInfo.sumPrice,
        promPrice: 0,
        userId: route.query.userId,
        shopId: adminInfo.shopId,
        itemName: itemname.join(),
        orderDate: dayjs(new Date()).format('YYYYMMDDHHmmss'),
        empId: consumeList.value[0].teacherId,
        cardId: consumeList.value[0].cardId,
        qty: consumeList.value[0].buyNum,
        type: 2,
        balance: currentOptBalance.value,
        consumeList: consumeList.value
      }
      cache.setCache("currentPayEmpid",obj.empId)

      store.dispatch('order/addOrderAction', obj)
      emptyForm()
    }

    const emptyForm = () => {
      currentOptTeacherName.value = ''
    }

    emitter.on('changeCustomCardClick', item => {
      const index = consumeList.value.findIndex(ele => ele.cardId == item.cardId)
      if (index == -1) {
        consumeList.value.push({
          ...item,
          maxQty: item.buyRoleQty,
          buyNum: 1,
          teacherId: '',
          teacherName: ''
        })
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }

    })

    onUnmounted(() => {
      emitter.off('changeCustomCardClick')
    })

    const currentOptTeacherName = ref('')
    const currentOptCardIndex = ref(null)
    const changeTeacherId = obj => {
      consumeList.value[currentOptCardIndex.value].teacherId = obj.empId
      consumeList.value[currentOptCardIndex.value].teacherName = obj.teacherName
    }

    const currentOptBalance = ref([])

    const changeOptPayCheckList = list => {
      currentOptBalance.value = list
    }

    const emptyOptList = () => {
      consumeList.value = []
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    const changeRemoveCardOpt = cardId => {
      const index = consumeList.value.findIndex(item => item.cardId == cardId)
      if (index != -1) {
        consumeList.value.splice(index, 1)
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }

    }

    const changeCardQty = () => {
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    const handlePayInfo = (arr) => {
      let sum = 0
      arr.forEach(item => {
        sum += Number(item.salePrice * item.buyNum)
      });
      return sum
    }

    const handleOptTeacher = (index) => {
      dialogVisible.value = true
      currentOptCardIndex.value = index
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        currentOptTeacherName.value = ''
        currentOptBalance.value = []
        currentOptTeacherId.value = ''
        emptyOptList()
      }
    }, {
      deep: true,
      immediate: true
    })




    return {
      currentOptCardIndex,
      emptyForm,
      currentOptTeacherName,
      handlePayClick,
      changeTeacherId,
      currentOptBalance,
      changeOptPayCheckList,
      emptyOptList,
      changeRemoveCardOpt,
      changeCardQty,
      handlePayInfo,
      handleOptTeacher,
      dialogVisible,
      changeDialogVisible,
      currentOptTeacherId,
      consumeList,
      payInfo,
      dayjs
    }

  }
})
</script>

<style scoped lang="less">
.custom-card {
  padding-left: 20px;
}
</style>
