<template>
  <div class="dialog-form">
    <el-dialog @close="handleClise" v-model="isShow" title="选择销售/理疗师" width="500px">
      <el-scrollbar height="400px">
        <div class="teacher">
          <el-checkbox-group
            v-model="checkboxGroup"
            @change="handleChange"
          >
            <template :key="item" v-for="item in shopTeacher">
              <div style="width: 40%;height: 80px;">
                <el-checkbox
                  :label="item.userId"
                >
                <div style="display: flex">
                  <img :src="item.userPhoto" />
                  <p>{{ item.userRealname }}</p>
                </div>
                </el-checkbox>
              </div>
            </template>
          </el-checkbox-group>
        </div>
      </el-scrollbar>
      <div class="save-btn">
        <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    checkedList: {
      type: Array,
      default: []
    }
  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute();
    const isShow = ref(props.dialogVisible)
    const checkboxGroup = ref([])
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
      checkboxGroup.value = props.checkedList
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const shopTeacher = computed(() => store.state.order.shopTeacher)
    const initPage = () => {
      const admin = cache.getCache('adminInfo')
      store.dispatch('order/getShopTeacherAction', route.query?.shopId)
    }
    initPage()

    const currentIndex = ref(null)
    const handleTeacherClick = (item, index) => {
      // console.log(item);
      currentIndex.value = index
      emit('changeTeacherId', item.userId)
    }


    const handleClise = () => {
      checkboxGroup.value = []
    }
    const changeValue = ref({})
    const handleChange = v => {
      let value=v
      let teacherName = []
      checkboxGroup.value = v
      value.forEach(item => {
        let ele = shopTeacher.value.find(el => el.userId == item)
        if (ele != undefined) {
          teacherName.push(ele.userRealname)
        }
      })
      changeValue.value={empId: value,teacherName: teacherName.join()}
    }

    const handleSaveClick = () => {
      isShow.value = false
      emit('changeTeacherId', changeValue.value)
    }

    return {
      handleSaveClick,
      handleClise,
      handleChange,
      checkboxGroup,
      handleTeacherClick,
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      dayjs,
      shopTeacher,
      currentIndex


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  .save-btn {
    text-align: right;
  }

  .teacher {
    width: 90%;
    margin: 0 auto;
    div{
      display: flex;
      justify-content: space-between;
      // width: 48%;
      // margin: 10px 0;
      align-items: center;
      flex-wrap: wrap;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .actived{
    background:#409eff;
    color: #fff;
    border-radius: 8px;
  }
}
</style>
