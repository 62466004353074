<template>
  <div class="order-collection">
    <div class="bottom">
      <div v-if="$route.query.orderNo" class="order-comment">
        <el-collapse accordion>
          <el-collapse-item name="1">
            <template #title> 订单备注 </template>
            <div class="comment-form">
              <el-input show-word-limit maxlength="100" v-model="orderComment" :rows="2" type="textarea" placeholder="输入订单备注" />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="pay-wrap">
        <div class="pay-tips">
          <span>待支付:</span>
          <span class="price">¥{{ (payInfo.sumPrice / 100).toFixed(2) }}</span>
        </div>
        <div class="pay-opt">
          <el-checkbox-group v-if="!isBuyServe" v-model="optPayCheckList">
            <template v-if="userBalance.length > 0">
              <template v-if="userBalance[0].userCash > 0">
                <el-checkbox label="1">
                  本金支付:
                  {{ (userBalance[0].userCash / 100).toFixed(2) }}
                </el-checkbox>
              </template>
              <template v-else>
                <el-checkbox disabled label="1"> 本金支付: 0.00 </el-checkbox>
              </template>
            </template>
            <template v-else>
              <el-checkbox disabled label="1"> 本金支付: 0.00 </el-checkbox>
            </template>

            <template v-if="userBalance.length > 0">
              <template v-if="userBalance[1].userCash > 0">
                <!--<el-checkbox :disabled="!isBuyServe" label="2">-->
                <el-checkbox label="2">
                  赠金支付: {{ (userBalance[1].userCash / 100).toFixed(2) }}
                </el-checkbox>
              </template>
              <template v-else>
                <el-checkbox disabled label="2"> 赠金支付: 0.00 </el-checkbox>
              </template>
            </template>
            <template v-else>
              <el-checkbox disabled label="2"> 赠金支付: 0.00 </el-checkbox>
            </template>
          </el-checkbox-group>
          <div class="serve-page" v-else>
            <template v-if="isKaika2">
              <el-select @change="handleBalanceChange" v-model="optPayCheckList" multiple placeholder="账户余额支付" style="width: 240px; display: none;">
                <el-option v-for="item in userBalanceInfo" :label="item.name" :disabled="item.disabled" :key="item.id" :value="item.id">
                </el-option>
              </el-select>
              <el-select :disabled="optOtherPayDisabled" clearable v-model="optStoredValue" class="m-2" placeholder="储值卡支付" size="large">
                <el-option @click="handleCuziChange(item)" v-for="item in userCardListInfo" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
              <el-select :disabled="optOtherPayDisabled" clearable @change="handlezekouChange" v-model="zekouCardNo" placeholder="折扣卡支付" style="width: 240px">
                <el-option v-for="item in zekouCardList" :label="item.cardName + '（余额：' + (item.cardCash / 100).toFixed(2) + '）'" :key="item.cardNo" :value="item.cardNo">
                </el-option>
              </el-select>

              <el-select :disabled="optOtherPayDisabled" @change="handlexjqClick" clearable value-key="couponNo" v-model="xjqCoupponNo" placeholder="现金券支付" style="width: 240px">
                <el-option v-for="item in couponList" :label="item.couponName" :key="item.id" :value="item">
                </el-option>
              </el-select>
            </template>
          </div>

          <div class="pay-btn">
            <template v-if="!isBuyServe">
              <el-button v-if="isRecharge == true" :disabled="isOptRechargeType == true" @click="handlePayClick" type="primary">收款</el-button>
              <el-button v-else :disabled="optCardNum == 0" @click="handlePayClick" type="primary">收款</el-button>
            </template>
            <template v-else>
              <el-button @click="handlePayClick" type="primary">收款</el-button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <add-handsel-dialog :serveList="orderServeInfoList" @changeDialogVisible="handleAddHandelChangeDialogVisible" :dialogVisible="addHandeldialogVisible"></add-handsel-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, nextTick } from 'vue'
import AddHandselDialog from './cpns/add-handsel-dialog/add-handsel-dialog.vue'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import store from '@/store'
import { setReserveRemark } from '@/service/main/reserve'
import cache from '@/utils/cache'
import { ElMessage } from 'element-plus'
import { getCouponList } from '@/service/main/customer'

export default defineComponent({
  props: {
    payInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    optOtherPayDisabled: {
      type: Boolean,
      default: false
    },
    isShowBalancePay: {
      type: Boolean,
      default: true
    },
    optCardNum: {
      type: Number,
      default: 0
    },
    isRecharge: {
      type: Boolean,
      default: false
    },
    isOptRechargeType: {
      type: Boolean,
      default: true
    },
    isBuyServe: {
      type: Boolean,
      default: false
    },
    isKaika: {
      type: Boolean,
      default: true
    },
    isKaika2: {
      type: Boolean,
      default: false
    },
    userCardList: {
      type: Array,
      default() {
        return []
      }
    },
    zekouCardList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    AddHandselDialog,
  },
  emits: ['handlePayClick', 'changeOptPayCheckList', 'cuziChange', 'changeoptStoredValue', 'handlezekouChange'],
  setup(props, { emit }) {
    const route = useRoute()
    const userId = computed(() => route.query.userId)
    const consumeListState = useState('order', ['orderConsumeInfoList', 'orderServeInfoList'])
    const addHandeldialogVisible = ref(false)
    const handleAddHandselClick = () => {
      addHandeldialogVisible.value = true
    }
    const handleAddHandelChangeDialogVisible = (flag) => {
      addHandeldialogVisible.value = flag
    }

    const handlePayClick = () => {
		// if (orderComment.value) {
		// 	handleOrderComment()
		// }
      emit('handlePayClick')
    }

    const handleBalanceChange = value => {
      //debugger
      emit('balanceChange', value)
    }

    const handleCuziChange = value => {
      emit('cuziChange', value)
    }


    const optStoredValue = ref('')
    const optPayCheckList = ref([])
    watch(optPayCheckList, (n) => {
      // console.log(n);
      emit('changeOptPayCheckList', n)
    }, {
      deep: true
    })

    watch(userId, (n) => {
      // console.log(n);
    }, {
      deep: true,
    })

    watch(optStoredValue, (n) => {
      // console.log(n);
      emit('changeoptStoredValue', n)
    }, {
      deep: true
    })

    const userBalance = computed(() => store.state.order.userBalance)
    const userBalanceInfo = ref([])
    watch(userBalance, (n) => {
      if (n != null && props.isBuyServe == true) {
        userBalanceInfo.value[0] = { disabled: ((n[0].userCash) / 100).toFixed(2) == '0.00', name: '本金账户可用:' + ((n[0].userCash) / 100).toFixed(2), id: 0 }
        userBalanceInfo.value[1] = { disabled: ((n[1].userCash) / 100).toFixed(2) == '0.00',  name: '赠金账户可用:' + ((n[1].userCash) / 100).toFixed(2), id: 1 }
      }
    }, {
      deep: true
    })

    const userCardListInfo = ref([])
    watch(() => props.userCardList, (n) => {
      if (n != null) {
        n.forEach((item, index) => {
          let bj = item.cardCash != null ? ((item.cardCash) / 100).toFixed(2) : '0.00'
          let zj = item.cardCashProm != null ? ((item.cardCashProm) / 100).toFixed(2) : '0.00'
          userCardListInfo.value.push({
            name: item.cardName + '(' + bj + '+' + zj + ')',
            value: item.cardNo + index,
            cardNo: item.cardNo,
            serveName: item.cardName,
            cardCash: item.cardCash,
            cardCashProm: item.cardCashProm,
            bj,
            zj
          })
        })
      }
    }, {
      deep: true
    })

    const zekouCardNo = ref('')
    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        // initPage()
        optPayCheckList.value = []
      }
    }, {
      deep: true,
    })

    onUnmounted(() => {
      emitter.off('changeOrderQrcodePayOPenPayDialog')
    })

    const orderComment = ref('')
    const handleOrderComment = async () => {
      const adminInfo = cache.getCache('adminInfo')
      const res = await setReserveRemark({
        orderNo: route.query.orderNo,
        memo: orderComment.value,
        userId: adminInfo.userId
      })
    }

    const handlezekouChange = value => {
      emit('handlezekouChange', value)
    }

    const couponList = ref([])
    const xjqCoupponNo = ref('')

    const handlexjqClick = item => {
      nextTick(() => {
        emit('changexjqPay',{
          price:item?item.promPrice:0,
          couponNo:item?xjqCoupponNo.value:''
        })
      })
    }


    const initPage = async () => {
      await store.dispatch('order/getUserBalanceAction', userId.value)
      const res = await getCouponList(route.query.userId)
      let list = res.data.list
      list = list.filter(ele => ele.used == 0)
      couponList.value = list
    }
    initPage()

    return {
      handlexjqClick,
      couponList,
      xjqCoupponNo,
      handleOrderComment,
      orderComment,
      handleCuziChange,
      handleBalanceChange,
      userCardListInfo,
      optStoredValue,
      userBalanceInfo,
      userBalance,
      userId,
      optPayCheckList,
      handlePayClick,
      ...consumeListState,
      handleAddHandselClick,
      addHandeldialogVisible,
      handleAddHandelChangeDialogVisible,
      zekouCardNo,
      handlezekouChange
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1824px) {
  .serve-page {
    &:deep(.el-select) {
      max-width: 120px !important;
      margin-bottom: 10px !important;
    }
  }
}

.order-collection {
  position: fixed;
  width: calc((100% - 212px) / 2);
  padding-right: 10px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 10px;

  &:deep(.el-collapse) {
    width: 100%;

    .el-collapse-item {
      border-top: none !important;
      border-bottom: none !important;
    }
  }

  .comment-wrap {
    background-color: red;
    height: 200px;
    width: 100%;
    position: relative;
    top: 100px;
  }

  .order-comment {
    position: relative;

    .comment-form {
      text-align: right;

      &:deep(.el-button) {
        margin-top: 10px;
      }
    }
  }

  .add-btn {
    min-height: 180px;
    color: rgba(121, 72, 234, 1);
    font-size: 14px;
    cursor: pointer;
  }

  .bottom {
    background-color: #fff;

    .order-comment,
    .pay-wrap {
      display: flex;
      justify-content: space-between;

      .pay-tips {
        .price {
          font-size: 24px;
          font-weight: 550;
        }
      }

      .pay-opt {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding-left: 10px;

        .pay-btn {
          margin-left: 20px;
        }

        .serve-page {
          &:deep(.el-select) {
            margin: 0 10px;
            max-width: 160px;
          }
        }
      }

      .price {
        color: rgba(212, 48, 48, 1);
        font-size: 24px;
      }
    }

    .order-comment {
      .expand {
        color: rgba(255, 87, 51, 1);
        font-size: 14px;
        cursor: pointer;
      }

      margin-bottom: 20px;
    }

    .pay-wrap {
      padding-top: 20px;
      border-top: 1px solid var(--borderColor);
    }
  }
}
</style>
