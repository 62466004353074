
<template>
  <div class="stored-value-card">
    <consume-detail-card :currentOptTeacherName="currentOptTeacherName" @radioOptChange="radioOptChange"
      :isStoredValue="true" @handleOptTeacher="handleOptTeacher" @changeCardQty="changeCardQty"
      @changeRemoveCardOpt="changeRemoveCardOpt" :consumeList="consumeList" @emptyOptList="emptyOptList">
    </consume-detail-card>
    <order-collection @changeOptPayCheckList="changeOptPayCheckList" :optCardNum="consumeList.length"
      @handlePayClick="handlePayClick" :payInfo="payInfo">
    </order-collection>
    <shopTeacherDialog @changeTeacherId="changeTeacherId" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" :checkedList='checkedList'></shopTeacherDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, watch } from 'vue'
import CardType from '../../../base-ui/card-type/card-type.vue'
import ConsumeDetailCard from '../../../base-ui/consume-detail-card/consume-detail-card.vue'
import OrderCollection from '../../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import cache from '@/utils/cache'
import { COMPANY_ID } from '@/common/constant'
import { useRouter } from 'vue-router'
import shopTeacherDialog from '../../../base-ui/shop-teacher-dialog/shop-teacher-dialog.vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    CardType,
    ConsumeDetailCard,
    OrderCollection,
    shopTeacherDialog
  },
  emits: ['openPay'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const consumeList = ref([])
    const payInfo = reactive({
      sumPrice: 0,
      date: dayjs(new Date()).format('YYYYMMDDHHmmss')
    })

    const emptyOptList = () => {
      consumeList.value = []
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        consumeList.value = []
      }
    })


    const changeRemoveCardOpt = cardId => {
      const index = consumeList.value.findIndex(item => item.cardId == cardId)
      if (index != -1) {
        consumeList.value.splice(index, 1)
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }
    }

    emitter.on('changeStoredValueCardClick', (item) => {
      // console.log(item);
      const index = consumeList.value.findIndex(ele => ele.cardId == item.cardId)
      if (index == -1) {
        consumeList.value=[{
          ...item,
          maxQty: item.buyRoleQty,
          buyNum: 1,
          teacherId: route.query.empId?[Number(route.query.empId)]:[],
          teacherName: route.query.empName||'',
          cashIn: -1,
          cashProm: -1
        }]
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }
    })

    const changeCardQty = () => {
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    const currentOptBalance = ref([])
    const changeOptPayCheckList = list => {
      currentOptBalance.value = list
    }

    const handlePayInfo = (arr) => {
      let sum = 0
      arr.forEach(item => {
        sum += Number(item.salePrice * item.buyNum)
      });
      return sum
    }

    onUnmounted(() => {
      emitter.off('changeStoredValueCardClick')
    })

    const currentPayRule = reactive({
      cardCash: "",
      promCash: ""
    })

    const handlePayClick = () => {
      // console.log(typeof consumeList.value[0].cashIn);
      // let flag1 = consumeList.value.every(item => item.cashIn != -1)
      // let flag2 = consumeList.value.every(item => item.cashProm != -1)
      let flag3 = consumeList.value.every(item => item.teacherId.length > 0)
      // if (flag1 == false || flag2 == false) {
      //   ElMessage({
      //     message: '请选择充值规格!',
      //     type: 'warning',
      //   })
      //   return
      // }

      if (flag3 == false) {
        ElMessage({
          message: '请选择理疗师!',
          type: 'warning',
        })
        return
      }
      let cardList = consumeList.value.map(item=>{
        return {cardId:item.cardId}
      })
      let obj = {
        userId:route.query.userId,
        cardList,
        saleIds:consumeList.value[0].teacherId,
        shopId:route.query.shopId
      }
      emit('openPay',obj)

    }

    const emptyForm = () => {
      currentOptTeacherName.value = ''
    }

    const currentOptTeacherId = ref('')
    const currentOptTeacherName = ref('')
    const changeTeacherId = obj => {
      consumeList.value[currentOptCardIndex.value].teacherId = obj.empId
      consumeList.value[currentOptCardIndex.value].teacherName = obj.teacherName
    }

    const dialogVisible = ref(false)
    const currentOptCardIndex = ref(null)
    const checkedList = ref([])
    const handleOptTeacher = (index) => {
      dialogVisible.value = true
      currentOptCardIndex.value = index
      checkedList.value = consumeList.value[index].teacherId||[];
    }

    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const radioOptChange = ({ item, index }) => {

      consumeList.value[index].cashIn = item.cashIn
      consumeList.value[index].cashProm = item.cashProm
      // console.log(consumeList.value[index]);
    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        currentOptTeacherName.value = ''
        currentOptBalance.value = []
        currentOptTeacherId.value = ''
        emptyOptList()
      }
    }, {
      deep: true,
      immediate: true
    })


    return {
      changeOptPayCheckList,
      checkedList,
      currentOptBalance,
      currentOptCardIndex,
      currentPayRule,
      radioOptChange,
      changeDialogVisible,
      currentOptTeacherId,
      changeTeacherId,
      handleOptTeacher,
      currentOptTeacherName,
      dialogVisible,
      emptyOptList,
      consumeList,
      changeRemoveCardOpt,
      handlePayInfo,
      payInfo,
      changeCardQty,
      handlePayClick,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.stored-value-card {
  padding-left: 20px;
}
</style>









