<template>
  <div class="upload">
    <el-upload :headers="headers" :class="{ limitActive: imgUrlFlag && limit == 1 }"
      :action="UPLOAD_BASE_URL+'/manager/upload'" :list-type="listType" name="file"
      accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PBG, .GIF, .BMP" :on-success="handleUploadSuccess"
      :multiple="limit > 1" ref="uploadShopImgRef" :before-upload="handleBeforeUpload" :file-list="fileList"
      :on-remove="handleUploadRemove" :on-exceed="handleOnExceed" :limit="limit" :on-preview="handlePictureCardPreview">
      <slot>
        <el-icon>
          <plus />
        </el-icon>
      </slot>
    </el-upload>
    <el-dialog v-model="dialogVisible">
      <img w-full style="width:100%;height: 100%;" :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { tips } from '@/utils/tips'
import { Flag } from '@element-plus/icons'
import { UPLOAD_BASE_URL } from '@/common/constant'
export default defineComponent({
  props: {
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    limit: {
      type: Number,
      default: 5
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  emits: ['handleUploadSuccess', 'handleUploadRemove', 'handleUploadEmpty', 'handleAddImgUploadRemove'],
  setup(props, { emit }) {
    const uploadShopImgRef = ref(null)
    const currentLimitFlag = ref(false)
    const imgUrlFlag = ref(false)
    const handleUploadSuccess = (res, fileList) => {
      imgUrlFlag.value = true
      emit('handleUploadSuccess', { ...res, ...fileList })
    }

    const headers = ref()
    const initPage = () => {
      try {
        let value = document.querySelector("meta[name='_csrf']").content
        let key = document.querySelector("meta[name='_csrf_header']").content
        headers.value = {
          [key]: value
        }
      } catch (error) {
        // console.log(error);
      }
    }
    initPage()

    const emptyFileList = () => {
      uploadShopImgRef.value.clearFiles()
    }

    const handleOnExceed = () => {
      tips({ code: 1, msg: `最多上传${props.limit}张图片!` })
    }

    const handleBeforeUpload = (file) => {
      const size = file.size / 1024 / 1024;
      // console.log(file);
      // console.log(size);
    }

    const handleUploadRemove = (file, fileList) => {
      // console.log(file);
      imgUrlFlag.value = false
      if (file.response != null) {
        // console.log(0);
        emit('handleUploadRemove', file.response.data.fileName)
      } else {
        if (props.isAdd == true) {
          try {
            let url = file.response.data.fileName
            emit('handleAddImgUploadRemove', url)
            // console.log(1);
          } catch (error) {
            // console.log(error);
          }
        } else {
          imgUrlFlag.value = false
          if (props.limit == 1) {
            emptyFileList()
            emit('handleUploadEmpty')
            // console.log(2);
            return
          }
          const arr = file.url.split('/uploads')
          const url = '/uploads' + arr[1]
          // console.log(arr);
          emit('handleUploadRemove', url)
        }
      }


    }
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url
      dialogVisible.value = true
    }

    return {
      headers,
      handleUploadSuccess,
      uploadShopImgRef,
      emptyFileList,
      handleBeforeUpload,
      handleUploadRemove,
      handleOnExceed,
      currentLimitFlag,
      imgUrlFlag,
      UPLOAD_BASE_URL,
      handlePictureCardPreview,
      dialogImageUrl,
      dialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.upload {
  .limitActive {
    &:deep(.el-upload--picture-card) {
      display: none !important;
    }
  }
}
</style>
