<template>
  <div class="secondary-card">
    <consume-detail-card :currentOptTeacherName="currentOptTeacherName" @handleOptTeacher="handleOptTeacher"
      @changeCardQty="changeCardQty" @changeRemoveCardOpt="changeRemoveCardOpt" :consumeList="consumeList"
      @emptyOptList="emptyOptList">
    </consume-detail-card>
    <order-collection @changeOptPayCheckList="changeOptPayCheckList" :optCardNum="consumeList.length"
      @handlePayClick="handlePayClick" :payInfo="payInfo">
    </order-collection>
    <shopTeacherDialog @changeTeacherId="changeTeacherId" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" :checkedList='checkedList'></shopTeacherDialog>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, onUnmounted } from 'vue'
import CardType from '../../../base-ui/card-type/card-type.vue'
import ConsumeDetailCard from '../../../base-ui/consume-detail-card/consume-detail-card.vue'
import OrderCollection from '../../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import cache from '@/utils/cache'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'
import shopTeacherDialog from '../../../base-ui/shop-teacher-dialog/shop-teacher-dialog.vue'
import { isatty } from 'tty'
import { isArray } from '@vue/shared'

export default defineComponent({
  props: {

  },
  components: {
    CardType,
    ConsumeDetailCard,
    OrderCollection,
    shopTeacherDialog
  },
  emits: ['openPay'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const consumeList = ref([])

    const emptyOptList = () => {
      consumeList.value = []
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    const payInfo = reactive({
      sumPrice: 0,
      date: dayjs(new Date()).format('YYYYMMDDHHmmss')
    })

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        consumeList.value = []
      }
    })


    const changeRemoveCardOpt = cardId => {
      const index = consumeList.value.findIndex(item => item.cardId == cardId)
      if (index != -1) {
        consumeList.value.splice(index, 1)
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }
    }


    emitter.on('changeSecondaryCardClick', item => {
      const index = consumeList.value.findIndex(ele => ele.cardId == item.cardId)
      if (index == -1) {
        consumeList.value=[{
          ...item,
          maxQty: item.buyRoleQty,
          buyNum: 1,
          teacherId: route.query.empId?[Number(route.query.empId)]:[],
          teacherName: route.query.empName||''
        }]
        const sumPrice = handlePayInfo(consumeList.value)
        payInfo.sumPrice = sumPrice
      }

    })

    const changeCardQty = () => {
      const sumPrice = handlePayInfo(consumeList.value)
      payInfo.sumPrice = sumPrice
    }

    const handlePayInfo = (arr) => {
      let sum = 0
      arr.forEach(item => {
        sum += Number(item.salePrice * item.buyNum)
      });
      return sum
    }

    onUnmounted(() => {
      emitter.off('changeSecondaryCardClick')
    })

    const currentOptTeacherId = ref('')
    const currentOptBalance = ref([])
    const handlePayClick = () => {
      let cardList = consumeList.value.map(item=>{
        return {cardId:item.cardId}
      })
      let obj = {
        userId:route.query.userId,
        cardList,
        saleIds:consumeList.value[0].teacherId,
        shopId:route.query.shopId
      }
      emit('openPay',obj)
      // cache.setCache("currentPayEmpid",obj.empId)
      // // console.log(obj);
      // store.dispatch('order/addOrderAction', obj)
      // emptyForm()
    }
    const emptyForm = () => {
      currentOptTeacherName.value = ''
    }
    const currentOptCardIndex = ref(null)
    const checkedList = ref([])
    const handleOptTeacher = (index) => {
      dialogVisible.value = true
      currentOptCardIndex.value = index
      checkedList.value = consumeList.value[index].teacherId||[];
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const currentOptTeacherName = ref('')
    const changeTeacherId = obj => {
      consumeList.value[currentOptCardIndex.value].teacherId = obj.empId
      consumeList.value[currentOptCardIndex.value].teacherName = obj.teacherName
    }

    const changeOptPayCheckList = list => {
      currentOptBalance.value = list
    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        currentOptTeacherName.value = ''
        currentOptBalance.value = []
        currentOptTeacherId.value = ''
        emptyOptList()
      }
    }, {
      deep: true,
      immediate: true
    })

    return {
      currentOptCardIndex,
      checkedList,
      currentOptBalance,
      changeOptPayCheckList,
      currentOptTeacherId,
      changeTeacherId,
      changeDialogVisible,
      currentOptTeacherName,
      dialogVisible,
      consumeList,
      changeRemoveCardOpt,
      handlePayInfo,
      payInfo,
      changeCardQty,
      handlePayClick,
      emptyOptList,
      handleOptTeacher,
    }

  }
})
</script>

<style scoped lang="less">
.secondary-card {
  padding-left: 20px;
  position: relative;
}
</style>




