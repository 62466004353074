export const rankProps = {
  value: 'lvId',
  label: 'levelName',
  checkStrictly: true
}

export const genderList = [
  {
    label:'男',
    value:'1'
  },
  {
    label:'女',
    value:'2'
  }
]
