<template>
  <div class="add-handsel-dialog">
    <page-dialog
      :width="40"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="添加赠送"
      class="dialog"
    >
      <el-tabs type="card" class="tabs" @tab-click="handleClick">
        <el-tab-pane label="服务">
          <div class="titles">
            <span class="left">项目</span>
            <span class="right">操作</span>
          </div>
          <div class="top-content">
            <div class="left">
              <el-select v-model="value" class="el-select1" placeholder="所有分类" size="large">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select v-model="value" class="el-select2" placeholder="服务关键词" size="large">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="right">
              <div>
                请选择权益
                <el-icon>
                  <caret-bottom />
                </el-icon>
              </div>
              <div>
                请选择充值卡
                <el-icon>
                  <caret-bottom />
                </el-icon>
              </div>
            </div>
          </div>
          <div class="serve-list">
            <el-scrollbar height="350px">
              <template :key="item" v-for="item in serveList">
                <div class="item">
                  <div class="left">
                    <div class="pic">
                      <el-icon>
                        <eleme-filled />
                      </el-icon>
                    </div>
                    <div class="info">
                      <div class="name">{{ item.name }}</div>
                      <div class="price">¥{{ item.price.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="right">
                    <el-button size="small" plain>添加</el-button>
                  </div>
                </div>
              </template>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品">商品</el-tab-pane>
      </el-tabs>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    serveList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    return {
      changeDialogVisible,
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.add-handsel-dialog {
  .dialog {
    .titles {
      display: flex;
      justify-content: space-between;
      height: 48px;
      align-items: center;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      margin-bottom: 10px;
      .left {
        padding-left: 30px;
      }
      .right {
        padding-right: 76px;
      }
    }
    .top-content {
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        align-items: center;
        &:deep(.el-select) {
          max-width: 120px;
        }
      }
      .left {
        .el-select1 {
          margin-right: 10px;
        }
      }
      .right {
        color: rgba(80, 80, 80, 1);
        font-size: 12px;
        & > div:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
    .serve-list {
      margin-top: 16px;
      .item {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(221, 221, 221, 1);
        .left {
          display: flex;
          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .price {
              color: rgba(255, 87, 51, 1);
              font-size: 14px;
              margin-top: 6px;
            }
          }
          .pic {
            margin-right: 10px;
            i {
              font-size: 48px;
            }
          }
        }
        .right {
          width: 58px;
          height: 26px;
        }
      }
    }
  }
}
</style>




