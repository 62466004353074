<template>
  <div class="user-info">
    <div class="key">用户信息</div>
    <div @click="handleUserClick" class="value">
      <div>{{userInfo.username}}</div>
      <div>({{userInfo.phone}})</div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    let userInfo = ref()

    const handleUserClick = () => {
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: userInfo.value.userId,
          phone: userInfo.value.phone
        }
      })
    }

    const initPage = () => {
      let { userId, username, phone } = route.query

      userInfo.value = {
        userId,
        username,
        phone
      }
    }
    initPage()

    return {
      handleUserClick,
      userInfo
    }

  }
})
</script>

<style scoped lang="less">
.user-info {

  .key {}

  .value {
    font-size: 15px;
    padding-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    color: rgb(10, 96, 189);
  }
}
</style>




