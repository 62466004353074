<template>
  <div class="consume-detail-card">
    <div class="title">
      <span>消费明细({{ consumeList.length }})</span>
      <span v-if="consumeList.length > 0" @click="handleEmptyClick" class="other">清空</span>
    </div>

    <template :key="item" v-for="(item, index) in consumeList">
      <div :style="{ height: isCustomCard ? 'auto' : '194px' }" class="item">
        <div @click="handleRemoveClick(item.cardId)" class="close">
          <el-icon>
            <close />
          </el-icon>
        </div>
        <div v-if="isBilling" class="top">
          <div class="name">{{ item.serveName }}</div>
          <div class="buy-num">
            <el-input-number v-model="item.buyNum" :min="1" :max="item.maxQty" @change="handleQtyChange($event, item, index)" />
          </div>
          <div class="price">¥{{ ((item.paraList[0].salePrice) / 100).toFixed(2) }}</div>
        </div>
        <div v-else class="top">
          <div class="name">{{ item.cardName }}</div>
          <div v-if="!isStoredValue" class="buy-num">
            <el-input-number v-model="item.buyNum" :min="1" :max="item.maxQty" @change="handleQtyChange($event, item, index)" />
          </div>
          <div class="price">¥{{ ((item.salePrice) / 100).toFixed(2) }}</div>
        </div>

        <div :class="{ customCardActive: isCustomCard }" class="bottom">
          <div class="left">
            <template v-if="item.teacherName.length > 0">
              <div @click="handleOptTeacher(index)">理疗师：{{ item.teacherName}} <el-tag v-if="item.isNew">新客</el-tag><el-button :icon="Edit" size="small">+</el-button></div>
            </template>
            <template v-else>
              <div @click="handleOptTeacher(index)">+ 选择理疗师</div>
            </template>

          </div>
          <div v-if="isCustomCard" class="right">
            <el-select v-model="value" class="m-2" placeholder="请选择权益" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" class="m-2" placeholder="请选择充值卡" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div v-if="isStoredValue" class="recharge-info">
            <div class="left">
              <div class="title">充值金额</div>
              <div>¥{{ (item.exactPrice) / 100 }}</div>
            </div>
            <div class="right">
              <div class="title">赠送金额</div>
              <div>¥{{ (item.promPrice) / 100 }}</div>
            </div>
          </div>
        </div>

        <!-- <div v-if="isCustomCard" class="selected-item">
          <div class="titles">
            <div>已选项目</div>
            <div>原价</div>
            <div>单价</div>
            <div>可用次数</div>
            <div>小计</div>
            <div>操作</div>
          </div>
          <div class="select-list">
            <template :key="item" v-for="item in customCardSelectList">
              <div class="select-content">
                <div class="name">{{ item.name }}</div>
                <div class="old-price">{{ item.oldPrice }}</div>
                <div class="price">
                  <el-input v-model="input">
                    <template #suffix>| 元</template>
                  </el-input>
                </div>
                <div class="use-num">
                  <el-input-number v-model="num" :min="1" :max="10" @change="handleChange" />
                </div>
                <div class="total">
                  <el-input v-model="input">
                    <template #suffix>| 元</template>
                  </el-input>
                </div>
                <div class="handle">删除</div>
              </div>
            </template>
          </div>
        </div> -->
      </div>
    </template>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import emitter from '@/utils/eventbus'
import rechargeCard from './cpns/recharge-card/recharge-card.vue'
import shopTeacherDialog from '../shop-teacher-dialog/shop-teacher-dialog.vue'

export default defineComponent({
  props: {
    consumeList: {
      type: Array,
      default() {
        return []
      }
    },
    currentOptTeacherName: {
      type: String,
      default: ''
    },
    isCustomCard: {
      type: Boolean,
      default: false
    },
    isBilling: {
      type: Boolean,
      default: false
    },
    isStoredValue: {
      type: Boolean,
      default: false
    },
    customCardSelectList: {
      type: Array,
      default() {
        return []
      }
    }

  },
  components: {
    rechargeCard,
    shopTeacherDialog
  },
  emits: ['changeRemoveCardOpt', 'changeCardQty', 'emptyOptList', 'radioOptChange'],
  setup(props, { emit }) {


    const ruleForm = reactive({

    })

    const handleRemoveClick = (cardId) => {
      emit('changeRemoveCardOpt', cardId)
    }

    const handleQtyChange = (e, item, index) => {

      emit('changeCardQty')
    }

    const handleEmptyClick = () => {
      emit('emptyOptList')
    }

    const handleOptTeacher = (index) => {
      emit('handleOptTeacher', index)
    }

    const radioOptChange = (item, index) => {
      // console.log(index);
      emit('radioOptChange', {
        item,
        index
      })
    }


    return {
      radioOptChange,
      handleEmptyClick,
      handleRemoveClick,
      ruleForm,
      handleQtyChange,
      handleOptTeacher
    }

  }
})
</script>

<style scoped lang="less">
.consume-detail-card {
  margin-top: 20px;
  padding-bottom: 120px;

  .recharge-info {
    display: flex;

    .left {
      margin-right: 10px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(80, 80, 80, 1);
    margin-bottom: 20px;

    .other {
      color: rgba(255, 87, 51, 1);
      cursor: pointer;
    }
  }

  .item {
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    border-radius: 5px;
    font-size: 14px;
    padding: 20px;
    min-height: 180px;
    height: auto !important;
    position: relative;
    margin-bottom: 10px;

    .close {
      position: absolute;
      right: 10px;
      top: 4px;

      i {
        font-size: 24px;
        color: rgba(153, 153, 153, 1);
        cursor: pointer;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;

      .buy-num {
        &:deep(.el-input-number) {
          max-width: 120px;
        }
      }

      .name,
      .price {
        color: rgba(80, 80, 80, 1);
        // color: var(--borderColor);
        font-size: 16px;
        font-weight: bold;
        padding-right: 42px;
      }
    }

    .customCardActive {
      position: relative !important;
      border-top: none !important;
      margin: 10px 0;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // position: absolute;
      width: 94%;
      // bottom: 20px;
      // border-top: 1px solid #bfbfbf;
      padding-top: 40px;

      &:deep(.el-select) {
        max-width: 120px;
        margin-left: 10px;
      }

      .left {
        cursor: pointer;
      }
    }

    .selected-item {
      .titles {
        display: flex;
        background-color: rgba(186, 186, 186, 1);
        height: 32px;
        line-height: 32px;
        font-size: 12px;

        div {
          flex: 1;
          text-align: center;
        }
      }

      .select-list {
        .select-content {
          display: flex;
          margin: 10px 0;

          .price,
          .total {
            &:deep(.el-input) {
              max-width: 100px;
            }
          }

          & > div {
            flex: 1;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:deep(.el-input-number) {
              max-width: 120px;

              .el-input-number__decrease {
              }
            }
          }
        }
      }
    }
  }
}
</style>

