<template>
  <div class="recharge">
    <div class="title">
      <span>消费明细</span>
    </div>
    <recharge-card
      :currentOptTeacherName="currentOptTeacherName"
      @handleAddTeacherClick="handleAddTeacherClick"
      @handleRemoveClick="handleRemoveClick"
      v-if="isShow"
      @radioOptChange="radioOptChange"
    >
    </recharge-card>
    <order-collection
      :isBuyServe="true"
      :isShowBalancePay="false"
      @handlePayClick="handlePayClick"
      :isOptRechargeType="isOptRechargeType"
      :isRecharge="true"
      :payInfo="payInfo"
    ></order-collection>
    <shopTeacherDialog
      @changeTeacherId="changeTeacherId"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      :actived='actived'
    ></shopTeacherDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, watch } from 'vue'
import CardType from '../../../base-ui/card-type/card-type.vue'
import OrderCollection from '../../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
import RechargeCard from './cpns/recharge-card/recharge-card.vue'
import emitter from '@/utils/eventbus'
import shopTeacherDialog from '../../../base-ui/shop-teacher-dialog/shop-teacher-dialog.vue'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    CardType,
    RechargeCard,
    OrderCollection,
    shopTeacherDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const payInfo = reactive({
      sumPrice: 0
    })

    const isShow = ref(false)
    const isOptRechargeType = ref(true)
    const radioOptChange = (item) => {
      payInfo.sumPrice = item.cashIn
      isOptRechargeType.value = false
      ruleForm.totalPrice = item.cashIn
      ruleForm.itemName = item.ruleName
      ruleForm.ruleId = item.id
      ruleForm.cardCash = item.cashIn
      ruleForm.promCash = item.cashProm
    }

    const consumeCardState = useState('order', ['consumeStoredValueCardInfoList'])

    emitter.on('changeOrderRechargeClick', () => {
      isShow.value = true
    })

    onUnmounted(() => {
      emitter.off('changeOrderRechargeClick')
    })

    const currentOptTeacherId = ref('')
    const currentOptTeacherName = ref('')
    const changeTeacherId = obj => {
      // console.log(obj);
      currentOptTeacherId.value = obj.empId
      ruleForm.empId = obj.empId
      currentOptTeacherName.value = obj.teacherName
    }

    const ruleForm = {
      companyId: COMPANY_ID,
      orderKind: 7,
      totalPrice: payInfo.sumPrice,
      promPrice: 0,
      userId: route.query.userId,
      shopId: '',
      itemName: '',
      orderDate: dayjs(new Date()).format('YYYYMMDDHHmmss'),
      empId: currentOptTeacherId.value,
      ruleId: '',
      cardCash: '',
      promCash: '',
      qty: 1,
      balance: '',
      type: 3,
      consumeList: [
        {
          name: '余额充值',
          kindId: 7
        }
      ]
    }

    const handlePayClick = () => {
      if (currentOptTeacherId.value.length != 0) {
        cache.setCache("currentPayEmpid",ruleForm.empId)
      }

      if (ruleForm.cardCash == '') {
        ElMessage({
          message: '请选择充值金额！',
          type: 'warning',
        })
        return
      }
        ruleForm.shopId = route.query.shopId
      //   const adminInfo = cache.getCache('adminInfo')
      //   ruleForm.shopId = adminInfo.shopId
      ruleForm.orderDate = dayjs(new Date()).format('YYYYMMDDHHmmss')
      // console.log({ ...ruleForm });
      store.dispatch('order/addOrderAction', { ...ruleForm })
      // emptyForm()

    }


    const emptyForm = () => {
      currentOptTeacherName.value = ''
      currentOptTeacherId.value = ''
      ruleForm.totalPrice = ''
      ruleForm.itemName = ''
      ruleForm.ruleId = ''
      ruleForm.cardCash = ''
      ruleForm.promCash = ''
      isShow.value = false
      isOptRechargeType.value = true
    }


    const handleRemoveClick = () => {
      isShow.value = false
      isOptRechargeType.value = true
      payInfo.sumPrice = 0
    }




    const dialogVisible = ref(false)
    const actived = ref(null)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
      if (flag) {
        actived.value=ruleForm.empId
      }
    }

    const handleAddTeacherClick = () => {
      dialogVisible.value = true
    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        emptyForm()
      }
    }, {
      deep: true,
      immediate: true
    })



    return {
      currentOptTeacherName,
      actived,
      handlePayClick,
      ruleForm,
      handleAddTeacherClick,
      dialogVisible,
      changeDialogVisible,
      currentOptTeacherId,
      changeTeacherId,
      handleRemoveClick,
      ...consumeCardState,
      payInfo,
      isOptRechargeType,
      radioOptChange,
      isShow,
      COMPANY_ID,
      dayjs
    }

  }
})
</script>

<style scoped lang="less">
.recharge {
  padding-left: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    color: rgba(80, 80, 80, 1);
    font-size: 14px;
    margin-bottom: 20px;

    .empty {
      color: rgba(255, 87, 51, 1);
    }
  }
}
</style>
