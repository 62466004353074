<template>
  <!-- 客户用户信息-充值-右侧消费明显模块 -->
  <div class="recharge-card">
    <div class="close">
      <el-icon @click="handleRemoveClick">
        <close />
      </el-icon>
    </div>
    <div class="top">
      <div class="title">
        <div class="default">默认账户</div>
        <div>充值金额</div>
        <div>赠送金额</div>
      </div>
      <div class="top-form">
        <div @click="handleAddTeacherClick" class="rechareg-obj">
          <span v-if="currentOptTeacherName.length == 0">+ 理疗师/销售</span>
          <!-- <span v-else>{{ currentOptTeacherName }} 更换理疗师/销售</span> -->
          <span v-else>{{ currentOptTeacherName }}</span>
        </div>
        <div>
          <el-input readonly v-model="ruleForm.cashIn" />
        </div>

        <div>
          <el-input readonly v-model="ruleForm.cashProm" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        <span>选择充值金额</span>
      </div>

      <div class="recharge-amount">
        <el-radio-group v-model="radio1" size="large">
          <template :key="item" v-for="item in rechargeRuleList">
            <el-radio-button @click="handleRechargeClick(item)" :label="item.id">
              <div class="radio-item">
                <div>{{ (item.cashIn / 100).toFixed(2) }}</div>
                <div>赠送{{ (item.cashProm / 100).toFixed(2) }}</div>
              </div>
            </el-radio-button>
          </template>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {
    currentOptTeacherName: {
      type: String,
      default: ""
    }
  },
  components: {

  },
  emits: ['radioOptChange', 'handleRemoveClick', 'handleAddTeacherClick',],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const radio1 = ref(null)

    const rechargeRuleList = computed(() => store.state.order.rechargeRuleList)

    const initPage = () => {
      store.dispatch('order/getRechargeRuleListAction', COMPANY_ID)
    }
    initPage()

    const ruleForm = reactive({
      cashProm: '0.00',
      cashIn: "0.00"
    })

    const handleRechargeClick = item => {
      // console.log(item);
      ruleForm.cashProm = (item.cashProm) / 100
      ruleForm.cashIn = (item.cashIn) / 100
      emit('radioOptChange', item)

    }

    const handleRemoveClick = () => {
      emit('handleRemoveClick')
    }

    const handleAddTeacherClick = () => {
      emit('handleAddTeacherClick')
    }

    emitter.on('changeCollectionOpenCardRecharge', (item) => {
      radio1.value = item.itemId
      const ele = rechargeRuleList.value.find(ele => ele.id == item.itemId)
      if (ele != undefined) {
        handleRechargeClick(ele)
      }

    })

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        radio1.value = null
      }
    }, {
      deep: true,
      immediate: true
    })

    onUnmounted(() => {
      emitter.off('changeCollectionOpenCardRecharge')
    })


    return {
      handleAddTeacherClick,
      ruleForm,
      handleRechargeClick,
      radio1,
      COMPANY_ID,
      rechargeRuleList,
      handleRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.recharge-card {
  width: 100%;
  height: auto !important;
  background-color: rgba(239, 239, 239, 1);
  border-radius: 5px;
  font-size: 14px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  min-height: 260px;

  .close {
    position: absolute;
    right: 10px;
    top: 4px;

    i {
      font-size: 24px;
      color: rgba(153, 153, 153, 1);
      cursor: pointer;
    }
  }

  .top {
    .title,
    .top-form {
      display: flex;

      .rechareg-obj {
        color: rgba(80, 80, 80, 1);
        border-radius: 3px;
        font-size: 12px;

        span {
          cursor: pointer;
          padding: 0 16px;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 1);
          border: 2px dashed rgba(204, 204, 204, 1);
        }
      }
    }

    .title {
      margin-bottom: 16px;

      .default {
        color: rgba(80, 80, 80, 1);
        font-size: 16px;
        font-weight: bold;
      }

      div {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .top-form {
      &:deep(.el-input) {
        max-width: 126px;
      }

      & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bottom {
    // position: absolute;
    // bottom: 20px;
    margin-top: 20px;
    width: 95%;
    border-top: 1px solid rgba(204, 204, 204, 1);

    .title {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      & > span:nth-child(2) {
        color: rgba(42, 130, 228, 1);
        font-size: 12px;
      }
    }
  }
}
</style>
