<template>
  <div class="search">
    <template v-if="userInfomation.hasOwnProperty('userId') == true">
      <el-input @click="handleInputClick" readonly prefix-icon="search" v-model="searchValue"
        :placeholder="userInfomation.userRealname + userInfomation.userMobile" />
    </template>
    <template v-else>
      <el-input @change="handleSearchChange" maxlength="11" @keyup="handleInput" prefix-icon="search"
        v-model="searchValue" placeholder="手机号或姓名关键字" />
      <div v-if="isShowResult && searchResult.length > 0" class="result">
        <template :key="item" v-for="item in searchResult">
          <div @click="handleUserClick(item)" class="user-item">
            <div class="left">
              <div class="uname">{{  item.userMobile  }}</div>
              <div>{{  item.userRealname  }}</div>
            </div>
            <div @click.stop="handleClose" class="right">
              <el-icon>
                <el-icon>
                  <Close />
                </el-icon>
              </el-icon>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  debounce
} from 'lodash'
import { useRoute } from 'vue-router'
import { getUserInfo } from '@/service/main/order'
import { searchKeywordCustomer, getShopServeList } from '@/service/main/reserve'

export default defineComponent({
  props: {
    userInfomation: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const searchValue = ref('')
    const phone = computed(() => route.query.phone)
    const isShowResult = ref(false)
    const userInfo = ref('')

    const searchResult = ref([])
    const handleInput = async (item) => {
      const res = await searchKeywordCustomer({
        keyword: searchValue.value
      })
      searchResult.value = res.data.list
      isShowResult.value = true
      // console.log(res);

    }

    const handleInputClick = () => {
      let userInfo = props.userInfomation
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: userInfo.userId,
          phone: userInfo.userMobile
        }
      })
    }

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        searchResult.value = []
        isShowResult.value = false
      }
    }

    const handleUserClick = (item) => {
      searchValue.value = item.userRealname + item.userMobile
      router.push({
        path: '/main/order/openCard',
        query: {
          userId: item.userId,
          phone: item.userMobile,
        }
      })
    }


    onUnmounted(() => {
      // console.log('搜索注销');
    })

    onMounted(() => {
      window.onclick = function () {
        isShowResult.value = false
      }
    })

    const handleClose = () => {
      userInfo.value = null
      isShowResult.value = false
      searchValue.value = ''
    }

    return {
      handleInputClick,
      handleSearchChange,
      handleClose,
      searchValue,
      isShowResult,
      handleInput,
      userInfo,
      handleUserClick,
      searchResult
    }

  }
})
</script>

<style scoped lang="less">
.search {
  position: relative;
  display: flex;
  align-items: center;

  &:deep(.el-input__inner) {
    cursor: pointer;
  }

  &:deep(.el-input) {
    max-width: 350px;
  }


  .user-info {
    display: flex;

    &>div {
      margin-right: 8px;
    }
  }


  .result {
    position: absolute;
    width: 100%;
    max-width: 350px;
    line-height: 40px;
    background-color: rgb(255, 266, 255, .8);
    box-shadow: 0px 0px 10px 0px rgba(169, 169, 169, 0.5);
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    z-index: 99;
    top: 42px;

    .user-item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .left {
      display: flex;
    }

    .right {
      cursor: pointer;
    }

    .uname {
      margin-right: 10px;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
    }

  }
}
</style>




