<template>
  <div class="stored-value-card">
    <card-type :cardType="1" @changeOrderCardDetailClick="changeOrderCardDetailClick"
      @changeOrderCardTypeClick="changeOrderCardTypeClick" :cardList="cardList"></card-type>
    <cardDetailDialog :cardInfo="currentCardInfo" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"></cardDetailDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CardType from '../../../base-ui/card-type/card-type.vue'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import cardDetailDialog from '../../../base-ui/card-detail-dialog/card-detail-dialog'
import { isNewUser } from '@/service/main/reserve'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    cardList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    CardType,
    cardDetailDialog
  },
  setup() {

    const route = useRoute()
    const changeOrderCardTypeClick = async item => {
      const res = await isNewUser(route.query.userId)
      // if (res.data == item.buyerRoleFlag) {
      //   emitter.emit('changeStoredValueCardClick', item)
      // } else {
      //   ElMessage({
      //     message: '该用户无购买权限!',
      //     type: 'warning',
      //   })
      // }
      emitter.emit('changeStoredValueCardClick', item)
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = (flag) => {
      dialogVisible.value = flag
    }

    const changeOrderCardDetailClick = item => {
      // console.log(item);
      currentCardInfo.value = item
      dialogVisible.value = true
    }

    const currentCardInfo = ref({})

    return {
      changeOrderCardTypeClick,
      dialogVisible,
      changeDialogVisible,
      changeOrderCardDetailClick,
      currentCardInfo
    }

  }
})
</script>

<style scoped lang="less">
.stored-value-card {}
</style>




