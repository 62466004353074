<template>
  <div @click="handleDialogClick" class="page-dialog">
    <el-dialog
      :close-on-click-modal="false"
      align-center
      v-if="isShow"
      v-model="isShow"
      :title="title"
      :width="String(width).indexOf('px') != -1 ? width : width + '%'"
    >
      <slot></slot>
      <template v-if="isShowFooter == true" #footer>
        <slot name="footer">
          <span class="dialog-footer">
            <el-button type="primary" size="small" @click="handleSaveClick"
              >保存</el-button
            >
          </span>
        </slot>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 35
    },
    isShowFooter: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  emits: ['changeDialogVisible', 'handleSaveClick', 'handleDialogClick'],
  setup(props, { emit }) {
    const isShow = ref(props.dialogVisible)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const handleSaveClick = () => {
      isShow.value = false
      emit('handleSaveClick')
    }

    const handleDialogClick = () => {
      emit('handleDialogClick')
    }

    return {
      handleDialogClick,
      isShow,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
&:deep(.el-dialog__header) {
  border-bottom: 1px solid #ccc;
}

&:deep(.el-dialog) {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-dialog {
  font-size: 12px;
}
</style>
