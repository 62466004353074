<template>
  <div class="recharge-card">

    <el-radio-group v-model="radio1" size="large">
      <template :key="item" v-for="item in rechargeRuleList">
        <el-radio-button @click="handleRechargeClick(item)" :label="item.id">
          <div class="radio-item">
            <div>{{ ((item.cashIn) / 100).toFixed(2) }}</div>
            <div>赠送{{ ((item.cashProm) / 100).toFixed(2) }}</div>
          </div>
        </el-radio-button>
      </template>
    </el-radio-group>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { emit } from 'process'

export default defineComponent({
  props: {

  },
  components: {

  },
  emits: ['radioOptChange', 'handleRemoveClick', 'handleAddTeacherClick'],
  setup(props, { emit }) {
    const store = useStore()
    const radio1 = ref(null)

    const rechargeRuleList = computed(() => store.state.order.rechargeRuleList)

    const initPage = () => {
      store.dispatch('order/getRechargeRuleListAction', COMPANY_ID)
    }
    initPage()

    const ruleForm = reactive({
      cashProm: '0.00',
      cashIn: "0.00"
    })

    const handleRechargeClick = item => {
      ruleForm.cashProm = (item.cashProm) / 100
      ruleForm.cashIn = (item.cashIn) / 100
      emit('radioOptChange', item)

    }






    return {

      ruleForm,
      handleRechargeClick,
      radio1,
      COMPANY_ID,
      rechargeRuleList,
    }

  }
})
</script>

<style scoped lang="less">
.recharge-card {
  width: 100%;
  background-color: rgba(239, 239, 239, 1);
  border-radius: 5px;
  font-size: 14px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;

  .close {
    position: absolute;
    right: 10px;
    top: 4px;

    i {
      font-size: 24px;
      color: rgba(153, 153, 153, 1);
      cursor: pointer;
    }
  }

  .top {

    .title,
    .top-form {
      display: flex;

      .rechareg-obj {
        color: rgba(80, 80, 80, 1);
        border-radius: 3px;
        font-size: 12px;

        span {
          cursor: pointer;
          padding: 0 16px;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 1);
          border: 2px dashed rgba(204, 204, 204, 1);
        }
      }
    }

    .title {
      margin-bottom: 16px;

      .default {
        color: rgba(80, 80, 80, 1);
        font-size: 16px;
        font-weight: bold;
      }

      div {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .top-form {
      &:deep(.el-input) {
        max-width: 126px;
      }

      &>div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bottom {
    bottom: 20px;
    width: 95%;


    .title {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      &>span:nth-child(2) {
        color: rgba(42, 130, 228, 1);
        font-size: 12px;
      }
    }
  }
}
</style>




