<template>
  <div class="recharge">

    <div @click="handleUserClick" class="item">
      <div class="title">默认账户</div>
      <div class="money">

        <div>总额: ¥{{ userBalance.length > 0 ? (Number(userBalance[0].userCash) + Number(userBalance[1].userCash)) / 100
            :
            '0.00'
        }}
        </div>
        <div>本金: {{ userBalance.length > 0 ? ((userBalance[0].userCash) / 100).toFixed(2) : '0.00' }}</div>
        <div>赠金: {{ userBalance.length > 0 ? ((userBalance[1].userCash) / 100).toFixed(2) : '0.00' }}</div>
      </div>
      <div class="shop">归属门店: {{ '' }}</div>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const userAccountBalance = computed(() => store.state.order.userAccountBalance)
    const userBalance = computed(() => store.state.order.userBalance)

    const initPage = () => {
      // store.dispatch('order/getUserBalanceAction', route.query.userId)
      // console.log(route.query.userId);
    }
    initPage()

    const handleUserClick = () => {
      if (route.query.userId == undefined) {
        ElMessage({
          message: '请在上方搜索要操作的客户!',
          type: 'warning',
        })
        return
      }

      emitter.emit('changeOrderRechargeClick')
    }


    return {
      userAccountBalance,
      userBalance,
      handleUserClick
    }


  }
})
</script>

<style scoped lang="less">
.recharge {
  .item {
    width: 100%;
    height: 142px;
    position: relative;
    background-color: rgba(119, 145, 203, 1);
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .money {
      display: flex;
      margin-top: 24px;

      div {
        margin-right: 20px;
      }
    }

    .shop {
      width: 94.6%;
      position: absolute;
      bottom: 20px;
      font-size: 12px;
      border-top: 1px solid rgba(111, 125, 255, 1);
      ;
      padding-top: 10px;
    }
  }
}
</style>




