<template>
  <div class="card-type">
    <template :key="item" v-for="item in cardList">
      <div @click="handleCardClick(item)" :style="{ backgroundColor: backgroundColor }" class="item">
        <div class="top">
          <div class="name">{{ item.cardName }}</div>
          <div v-if="isShowNum" class="num">{{ item.num }}次</div>
        </div>
        <div class="center">
          <div class="price">¥{{ ((item.salePrice) / 100).toFixed(2) }}</div>
          <div v-if="item.handsel" class="handsel">赠送:¥{{ item.handsel }}</div>
        </div>
        <div class="bottom">
          <div v-if="cardType == 0" class="type">次卡</div>
          <div v-else-if="cardType == 1" class="type">储值卡</div>
          <div v-else-if="cardType == 2" class="type">定制卡</div>
          <div v-else-if="cardType == 3" class="type">充值</div>
          <div v-else-if="cardType == 4" class="type">折扣卡</div>
          <div v-if="isShowDetail" @click.stop="handleDetailClick(item)" class="detail">查看详情</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { emit } from 'process'
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { debounce } from 'lodash'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    isShowNum: {
      type: Boolean,
      default: false
    },
    isShowDetail: {
      type: Boolean,
      default: true
    },
    cardList: {
      type: Array,
      default() {
        return []
      }
    },
    backgroundColor: {
      type: String,
      default: 'rgba(203, 161, 119, 1)'
    },
    cardType: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['changeOrderCardTypeClick', 'changeOrderCardDetailClick'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const handleCardClick = (item) => {
      if (route.query.userId == undefined) {
        ElMessage({
          message: '请在上方搜索要操作的客户!',
          type: 'warning',
        })
        return
      }
      emit('changeOrderCardTypeClick', item)
    }

    const handleDetailClick = item => {
      emit('changeOrderCardDetailClick', item)
      // console.log(item);
    }


    return {
      handleDetailClick,
      handleCardClick
    }

  }
})
</script>

<style scoped lang="less">
.card-type {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 48%;
    min-width: 220px;
    height: 142px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    padding: 20px;
    margin: 0.66%;
    cursor: pointer;

    .top {
      display: flex;
      justify-content: space-between;

      .name {
        font-size: 16px;
      }

      .num {}
    }

    .center {
      margin-top: 28px;
      font-size: 16px;
      display: flex;

      .price {
        margin-right: 30px;
      }
    }

    .bottom {
      border-top: 1px solid white;
      padding-top: 10px;
      position: absolute;
      width: 88%;
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      .type {}

      .detail {}
    }
  }
}
</style>




