<template>
  <div class="open-card">
    <div class="top">
      <userInfo></userInfo>
    </div>
    <div class="bottom">
      <div class="title">开卡</div>
      <el-divider />
      <div class="card-main-content">
        <div class="card-left">
          <el-tabs
            v-model="openCardTabs"
            class="open-card-tabs"
            @tab-click="handleTabsClick"
          >
            <el-tab-pane name="right-secondary-card" label="次卡">
              <secondary-card :cardList="secondaryCardList"></secondary-card>
            </el-tab-pane>
            <el-tab-pane name="right-stored-value-card" label="储值卡">
              <stored-value-card :cardList="storedValueCardList"></stored-value-card>
            </el-tab-pane>
            <el-tab-pane name="right-discount-card" label="折扣卡">
              <!-- <custom-card :cardList="customCardList"></custom-card> -->
              <discountCard :cardList="discountCardList"></discountCard>
            </el-tab-pane>
            <!-- <el-tab-pane name="right-recharge" label="余额充值">
              <recharge :rechargeInfoList="rechargeRuleList"></recharge>
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="card-right">
          <keep-alive>
            <component :is="openCardTabs" @openPay="openPay"></component>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
  <payDialog
    :curentOrderInfo="curentOrderInfo"
    :payPrice="currentOrder.payPrice"
    :isBack="currentOrder.isBack"
    @changePayType="changePayType"
    @paySuccess="paySuccess"
    :orderKind="currentOrder.orderKind"
    :oid="currentOrder.oid"
    @changeDialogVisible="payChangeDialogVisible"
    :dialogVisible="paydialogVisible"
  >
  </payDialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted, watch, onMounted } from 'vue'
import search from '../base-ui/search/search.vue'
import SecondaryCard from './left-cpns/secondary-card/secondary-card.vue'
import StoredValueCard from './left-cpns/stored-value-card/stored-value-card.vue'
import discountCard from './left-cpns/discount-card/discount-card.vue'
import Recharge from './left-cpns/recharge/recharge'
import RightSecondaryCard from './right-cpns/secondary-card/secondary-card.vue'
import RightStoredValueCard from './right-cpns/stored-value-card/stored-value-card.vue'
import RightCustomCard from './right-cpns/custom-card/custom-card.vue'
import RightRecharge from './right-cpns/recharge/recharge'
import RightDiscountCard from './right-cpns/discount-card/discount-card.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import payDialog from '../base-ui/pay-dialog/pay-dialog.vue'
import { useRouter,useRoute } from 'vue-router'
import { getOrderDetail } from '@/service/main/order'
import userInfo from '../base-ui/user-info/user-info.vue'
import {cardPurchase} from '@/service/main/order'
import {pullCashier} from '@/service/main/reserve'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {

  },
  components: {
    search,
    SecondaryCard,
    StoredValueCard,
    Recharge,
    RightSecondaryCard,
    RightStoredValueCard,
    RightCustomCard,
    RightRecharge,
    payDialog,
    discountCard,
    RightDiscountCard,
    userInfo
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const openCardTabs = ref('right-secondary-card')
    const orderNo = computed(() => route.query.orderNo)
    const rechargeRuleList = computed(() => store.state.order.rechargeRuleList)
    const secondaryCardList = computed(() => store.state.order.secondaryCardList)
    const storedValueCardList = computed(() => store.state.order.storedValueCardList)
    const discountCardList = computed(() => store.state.order.discountCardList)
    const customCardList = computed(() => store.state.order.customCardList)
    const currentOrder = reactive({
      oid: '',
      orderKind: 0,
      payPrice: 0,
      isBack: false
    })


    const initPage = () => {
      store.dispatch('order/getCardListAction',{userId:route.query.userId, shopId:route.query.shopId})
      store.dispatch('order/getRechargeRuleListAction', COMPANY_ID)
    }
    initPage()

    const paydialogVisible = ref(false)
    const payChangeDialogVisible = flag => {
      paydialogVisible.value = flag
    }

    const curentOrderInfo = ref('')
    emitter.on('changeOrderQrcodePayOPenPayDialog', (obj) => {
      curentOrderInfo.value = obj
      paydialogVisible.value = true
      currentOrder.oid = obj.oid
      currentOrder.orderKind = obj.orderKind
      currentOrder.payPrice = obj.payPrice
      currentOrder.isBack = obj.isBack

      // console.log(currentOrder.oid);
      setTimeout(() => {
        emitter.emit('changeOrderPayDialogSearchPayState')
      }, 2000);
    })

    const openPay = async(params) => {
      let res = await cardPurchase(params)
      if (res.code!=0) {
        ElMessage({
          message: res.msg,
          type: 'error'
        })
        return
      }
      let payData = await pullCashier(res.data.orderId)
      curentOrderInfo.value = payData.data
      paydialogVisible.value=true
      setTimeout(() => {
        emitter.emit('changeOrderPayDialogSearchPayState')
      }, 2000);
    }
    emitter.on('changeOrderQrCodePagePaySuccess', () => {
      paydialogVisible.value = false
    })


    const paySuccess = () => {
      paydialogVisible.value = false
      router.push('/main/reserve/check')
    }

    const changePayType = flag => {
      // console.log(flag);
    }



    const setOrderDetail = async (oid) => {
      const res = await getOrderDetail(COMPANY_ID, oid)
      let orderList = res.data.orderSku
      const orderType = res.data.orderDetail.orderKind
      if (orderType == 7) {
        openCardTabs.value = 'right-recharge'
        setTimeout(() => {
          emitter.emit('changeOrderRechargeClick')

        }, 100);
        setTimeout(() => {
          emitter.emit('changeCollectionOpenCardRecharge', orderList[0])
        }, 200);
      }

    }

    watch(() => route.path, (n) => {
      if (n == '/main/order/openCard') {
        if (orderNo.value != undefined) {
          setOrderDetail(orderNo.value)
        }
      }
    }, {
      deep: true,
      immediate: true
    })

    onUnmounted(() => {
      emitter.off('changeOrderQrCodePagePaySuccess')
      emitter.off('changeOrderQrcodePayOPenPayDialog')
    })

    onMounted(() => {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function (e) {
        history.pushState(null, null, document.URL);
      }, false);
    })


    return {
      discountCardList,
      curentOrderInfo,
      changePayType,
      paySuccess,
      payChangeDialogVisible,
      paydialogVisible,
      openPay,
      openCardTabs,
      COMPANY_ID,
      rechargeRuleList,
      secondaryCardList,
      storedValueCardList,
      customCardList,
      currentOrder,
      orderNo,
      setOrderDetail
    }

  }
})
</script>

<style scoped lang="less">
.open-card {
  margin: 20px 20px 0 20px;
  padding: 20px;
  background-color: #fff;
  min-height: 850px;

  .bottom {
    .card-main-content {
      display: flex;

      & > div {
        flex: 1;
      }

      .card-left {
      }

      .card-right {
      }
    }
  }

  .top {
    margin-bottom: 20px;
  }
}
</style>
