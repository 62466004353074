<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" destroy-on-close @close="handleDialogClose" :close-on-click-modal="false" v-model="isShow"
      title="收款台" width="82%">
      <pay @paySuccess="paySuccess" v-if="isShow" :reserveNo="reserveNo" :isKaika="isKaika" :curentOrderInfo="curentOrderInfo" :isBack="isBack"
        :payPrice="payPrice" @changePayType="changePayType" :orderKind="orderKind" :oid="oid"></pay>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import pay from '../../pay/pay.vue'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import { orderFallback } from '@/service/main/order'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    oid: {
      type: Number,
      default: -1
    },
    orderKind: {
      type: Number,
      default: 0
    },
    payPrice: {
      type: Number,
      default: 0
    },
    isBack: {
      type: Boolean,
      default: false
    },
    isKaika: {
      type: Boolean,
      default: false
    },
    curentOrderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    reserveNo: {
      type: Number,
      default: -1
    }
  },
  components: {
    UploadImg,
    pay

  },
  emits: ['changeDialogVisible','paySuccess'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const isShow = ref(props.dialogVisible)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const handleDialogClose = async() => {
      await orderFallback(props.curentOrderInfo.id)
      emitter.emit('changeOrderPayDialogClose')
    }

    const changePayType = item => {
      emit('changePayType', item)
    }
    const paySuccess = () => {
      emit('paySuccess')
    }



    onUnmounted(() => {
      isShow.value = false
    })

    return {
      changePayType,
      handleDialogClose,
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      paySuccess

    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  &:deep(.el-dialog) {
    min-height: 82vh;
    margin: 8vh auto;
  }

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
